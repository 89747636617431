import React, { Component } from 'react';
import style from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYelp, faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button/Button';


class Footer extends Component {
  state = {
      email: ''
  }

  onChange = (event) => {
      this.setState({
          email: event.target.value
      })
  }

  handleSubmit = (event) => {
      event.preventDefault();
      fetch('https://8p2sp46uw3.execute-api.us-east-1.amazonaws.com/dev', {
          method: 'POST',
          body: JSON.stringify({
              source: 'hvac_newsletter',
              email: this.state.email
          })
      })
      .then(res => res.json())
      .then(res => alert('Thank you for subscribing to our newsletter!'))
      .then(res => window.location.reload())
      .then(res => window.scrollTo(0,0))
      .catch(err => console.error('Error:', err))
  }

    render() {
      return (
          <footer className={style.Footer}>
            <div className={style.ContactContainer}>
              <ul className="wrapper">
                <li>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                  />
                  Address
                  <a href="https://www.google.com/maps/place/1086+Frances+Dr,+Streamwood,+IL+60107/@42.0105293,-88.1517097,17z/data=!3m1!4b1!4m5!3m4!1s0x880fa9c76e91a449:0xef24e44bc459e250!8m2!3d42.0105293!4d-88.149521">1086 Frances Dr, Streamwood</a>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                  />
                  Call/Text
                  <a href="tel:8475450688">(847)545-0688</a>
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faClock}
                  />
                  Open Hours
                  <a href="#home">Mon-Fri: 8am - 5:30pm</a>
                </li>
              </ul>
            </div>
            <div className={style.FooterContent}>
              <div className="wrapper">
                <ul className={style.FooterColumns}>
                  <li className={style.About}>
                    <h2>About HVAC Master</h2>
                    <p>At HVAC Master, we don't just provide residential and commercial heating and air conditioning services; we make people's lives easier. Better.</p>
                    <h4>Get a Quote</h4>
                    <span className={style.Number}>847 545 0688</span>
                  </li>
                  <li className={style.Links}>
                    <h2>Quick Links</h2>
                    <ul>
                      <li><a href="#services">Services</a></li>
                      <li><a href="#contact">Request Appointment</a></li>
                      <li><a href="#about">About Us</a></li>                  </ul>
                  </li>
                  <li className={style.Newsletter}>
                    <h2>Newsletter</h2>
                    <form onSubmit={this.handleSubmit}>
                        <input
                          placeholder="Enter your email"
                          type="email"
                          value={this.state.value}
                          onChange={this.onChange}
                        />
                        <button type="submit">Submit Now</button>
                      </form>
                  </li>
                </ul>
              </div>
            </div>
            <div className={style.Copyright}>
              <div className="wrapper">
                <div className={style.CopyrightContent}>
                  <div className={style.CopyrightText}>
                    HVAC Master ©{new Date().getFullYear()}, Coded by
                    {` `}
                    <a href="https://jacobadcock.com">Jacob Adcock</a>
                  </div>
                  <ul className={style.SocialIcons}>
                    <li>
                      <a href="https://facebook.com/HVACmasterIL">
                        <FontAwesomeIcon
                          icon={faFacebookF}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.yelp.com/biz/hvac-master-streamwood-2">
                        <FontAwesomeIcon
                          icon={faYelp}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/masterfinishil">
                        <FontAwesomeIcon
                          icon={faInstagram}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://g.page/hvacmaster/review?rc">
                        <FontAwesomeIcon
                          icon={faGoogle}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>  
            </div>
          </footer>
      );
    }
};

export default Footer;